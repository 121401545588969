import React from 'react'
import Navigation from "./Navigation";

function NotFound() {
    return (
        <div>
            <Navigation></Navigation>
        </div>
    )
}

export default NotFound
